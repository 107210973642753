import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { PageTitle } from "../../../../UI/atoms/PageTitle";
import TextField from "../../../../UI/atoms/TextField";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTypeOfGroupsToShow,
  selectUserNameSearchQuery,
  setSelectedTypeOfGroupsToShow,
  userNameSearch
} from "../../models/holiday-reports.duck";
import { ReactSelectDropDownList } from "../../../../UI/organisms/drop-down-list/ReactSelectDropDownList";
import { largeMobile } from "../../../../UI/atoms/MediaQueries";
import isMobile from "is-mobile";
import { HolidayReportDatePicker } from "./HolidayReportDatePicker";
import { selectAllTeams } from "../../../../../store/ducks/config.duck";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import {
  isCompanyAdminUser,
  isDepartmentAdmin,
  isOfficeAdminUser,
  isTeamAdminUser
} from "../../../../../utils/AccessControl";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";

export const SELECTED_HOLIDAY_REPORTS_GROUP_ID = 'selectedHolidayReportsGroupId';

export function HolidayReportViewHeader(props: Props) {
  const dispatch = useDispatch();
  const userNameSearchQuery = useSelector(selectUserNameSearchQuery);
  const selectedGroupToShow = useSelector(selectTypeOfGroupsToShow);
  const teams = useSelector(selectAllTeams);
  const currentUser = useSelector(selectCurrentUser);
  const offices = useSelector(selectAllOfficeEntities);

  const rootOfficeId = useMemo(() => {
    if (currentUser?.defaultLocationId) {
      return offices.find((o: OfficeEntity) => o.id === currentUser?.defaultLocationId)?.id;
    }
  }, [offices, currentUser?.defaultLocationId])

  const teamsToShow = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isDepartmentAdmin(currentUser)) {
      return teams;
    } else if (isOfficeAdminUser(currentUser) && rootOfficeId) {
      return teams.filter(t => t.officeIds?.includes(rootOfficeId))
    } else if (isTeamAdminUser(currentUser)) {
      return teams.filter(t => t.id === currentUser?.teamEntity.id)
    }
    return [];
  }, [currentUser, teams, rootOfficeId])

  const onSearchChanged = (value: string) => {
    dispatch(userNameSearch(value));
  }

  const optionsForGroupToShow = useMemo(() => {
    const allOption = (isCompanyAdminUser(currentUser) || isDepartmentAdmin(currentUser)) ? [{value: 'ALL', label: 'Show: All'}] : [];
    return [
      {value: 'LINE_REPORTS', label: 'Show: Line reports'},
      {value: 'INDIRECT_LINE_REPORTS', label: 'Show: Indirect line reports'},
      ...allOption,
      ...teamsToShow.map(t => ({value: t.id, label: `${t.name} (${t.memberCount})`}))
    ]
  }, [teamsToShow, currentUser]);

  const optionsForSort = [{value: 'User name', label: 'Sort: User name'}]

  const onGroupToShowChanged = (selection: any) => {
    dispatch(setSelectedTypeOfGroupsToShow(selection.value));
  }

  const onSortChanged = (selection: any) => {
  }

  return (
    <Wrapper>
      <PageHeader>
        <PageTitle style={{marginTop: 0}} text={'holidays.leave-reporting'} />
        {isMobile() && <HolidayReportDatePicker />}
        {false && <TextField onChange={onSearchChanged}
                             icon={IconTypes.Search}
                             value={userNameSearchQuery}
                             disabled={true}
                             placeholder={'manage-users-page.search'} />}
      </PageHeader>


      <ContentControls>
        <ReactSelectDropDownList selectedItem={optionsForGroupToShow.find(g => selectedGroupToShow === g.value)}
                                 onItemSelected={onGroupToShowChanged} items={optionsForGroupToShow} />

        <ReactSelectDropDownList selectedItem={optionsForSort[0]}
                                 disabled={true}
                                 onItemSelected={onSortChanged}
                                 items={optionsForSort} />
      </ContentControls>
    </Wrapper>
  )
}

interface Props {
}

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (${largeMobile}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ContentControls = styled.div`
  display: flex;
  flex-direction: column;

  @media (${largeMobile}) {
    flex-direction: row;
    justify-content: space-between;
    & > * {
      width: 250px;
    }
  }
`

const Wrapper = styled.div`
  .text-field {
    width: 100%;
  }
  .textField__inputRow {
    max-width: 100%;
  }
  .textField__inputRow p {
    white-space: nowrap;
  }
  
  margin-bottom: 16px;
  
  //display: flex;
  //justify-content: space-between;
`
