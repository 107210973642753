import { Moment } from "moment";
import { TeamEntity } from "./team.models";
import { OfficeEntity } from "../services/AdvanceHotDeskingService";
import { Tag } from "./tags.models";
import moment from "moment/moment";
import { DATE_FORMAT } from "../utils/DateUtils";
import { WhereaboutsOption } from "../services/WhereaboutOptions";


export class TeamWithMovements {
  constructor(public team: TeamEntity,
              public visible: boolean = false,
              public movementsLoaded: boolean = false,
              public movements?: any,
              public userDesks?: any,
              public restricted?: boolean) {
  }
}

export class TagWithMovements {
  constructor(public tag: Tag,
              public visible: boolean = false,
              public movementsLoaded: boolean = false,
              public movements?: MemberMovements[],
              public userDesks?: any,
              public restricted?: boolean) {
  }
}

export enum Period {
  AllDay = 'ALL',
  AM = 'AM',
  PM = 'PM'
}

export enum SideOfDay {
  AllDay = 'AllDay',
  AM = 'AM',
  PM = 'PM'
}

export function toSideOfDay(period: Period) {
  switch (period) {
    case Period.AM: return SideOfDay.AM;
    case Period.PM: return SideOfDay.PM;
    default: return SideOfDay.AllDay;
  }
}

export interface Movement {
  date: Moment;
  id?: string;
  localDate: string;
  mondayAmStatus: string;
  mondayPmStatus: string;
  mondayLocationId: number;
  mondayPmLocationId: number;
  tuesdayAmStatus: string;
  tuesdayPmStatus: string;
  tuesdayLocationId: number;
  tuesdayPmLocationId: number;
  wednesdayAmStatus: string;
  wednesdayPmStatus: string;
  wednesdayLocationId: number;
  wednesdayPmLocationId: number;
  thursdayAmStatus: string;
  thursdayPmStatus: string;
  thursdayLocationId: number;
  thursdayPmLocationId: number;
  fridayAmStatus: string;
  fridayPmStatus: string;
  fridayLocationId: number;
  fridayPmLocationId: number;

  saturdayAmStatus: string;
  saturdayPmStatus: string;
  saturdayLocationId: number;
  saturdayPmLocationId: number;

  sundayAmStatus: string;
  sundayPmStatus: string;
  sundayLocationId: number;
  sundayPmLocationId: number;

  userId: string;

  mondayAmDeskLocation?: number;
  mondayPmDeskLocation?: number;
  tuesdayAmDeskLocation?: number;
  tuesdayPmDeskLocation?: number;
  wednesdayAmDeskLocation?: number;
  wednesdayPmDeskLocation?: number;
  thursdayAmDeskLocation?: number;
  thursdayPmDeskLocation?: number;
  fridayAmDeskLocation?: number;
  fridayPmDeskLocation?: number;
  saturdayAmDeskLocation?: number;
  saturdayPmDeskLocation?: number;
  sundayAmDeskLocation?: number;
  sundayPmDeskLocation?: number;

  mondayAmDeskLabel?: string;
  tuesdayAmDeskLabel?: string;
  wednesdayAmDeskLabel?: string;
  thursdayAmDeskLabel?: string;
  fridayAmDeskLabel?: string;
  saturdayAmDeskLabel?: string;
  sundayAmDeskLabel?: string;
  mondayPmDeskLabel?: string;
  tuesdayPmDeskLabel?: string;
  wednesdayPmDeskLabel?: string;
  thursdayPmDeskLabel?: string;
  fridayPmDeskLabel?: string;
  saturdayPmDeskLabel?: string;
  sundayPmDeskLabel?: string;

  mondayAmGeneralParking?: boolean;
  mondayPmGeneralParking?: boolean;
  tuesdayAmGeneralParking?: boolean;
  tuesdayPmGeneralParking?: boolean;
  wednesdayAmGeneralParking?: boolean;
  wednesdayPmGeneralParking?: boolean;
  thursdayAmGeneralParking?: boolean;
  thursdayPmGeneralParking?: boolean;
  fridayAmGeneralParking?: boolean;
  fridayPmGeneralParking?: boolean;
  saturdayAmGeneralParking?: boolean;
  saturdayPmGeneralParking?: boolean;
  sundayAmGeneralParking?: boolean;
  sundayPmGeneralParking?: boolean;

  mondayAmParkingSpaceId?: number;
  mondayPmParkingSpaceId?: number;
  tuesdayAmParkingSpaceId?: number;
  tuesdayPmParkingSpaceId?: number;
  wednesdayAmParkingSpaceId?: number;
  wednesdayPmParkingSpaceId?: number;
  thursdayAmParkingSpaceId?: number;
  thursdayPmParkingSpaceId?: number;
  fridayAmParkingSpaceId?: number;
  fridayPmParkingSpaceId?: number;
  saturdayAmParkingSpaceId?: number;
  saturdayPmParkingSpaceId?: number;
  sundayAmParkingSpaceId?: number;
  sundayPmParkingSpaceId?: number;

  mondayAmParkingSpaceLabel?: string;
  mondayPmParkingSpaceLabel?: string;
  tuesdayAmParkingSpaceLabel?: string;
  tuesdayPmParkingSpaceLabel?: string;
  wednesdayAmParkingSpaceLabel?: string;
  wednesdayPmParkingSpaceLabel?: string;
  thursdayAmParkingSpaceLabel?: string;
  thursdayPmParkingSpaceLabel?: string;
  fridayAmParkingSpaceLabel?: string;
  fridayPmParkingSpaceLabel?: string;
  saturdayAmParkingSpaceLabel?: string;
  saturdayPmParkingSpaceLabel?: string;
  sundayAmParkingSpaceLabel?: string;
  sundayPmParkingSpaceLabel?: string;
}

export interface MovementEntity {
  date: string;
  id?: string;
  mondayAmStatus: string;
  mondayPmStatus: string;
  mondayLocationId: number;
  mondayPmLocationId: number;
  tuesdayAmStatus: string;
  tuesdayPmStatus: string;
  tuesdayLocationId: number;
  tuesdayPmLocationId: number;
  wednesdayAmStatus: string;
  wednesdayPmStatus: string;
  wednesdayLocationId: number;
  wednesdayPmLocationId: number;
  thursdayAmStatus: string;
  thursdayPmStatus: string;
  thursdayLocationId: number;
  thursdayPmLocationId: number;
  fridayAmStatus: string;
  fridayPmStatus: string;
  fridayLocationId: number;
  fridayPmLocationId: number;
  saturdayAmStatus: string;
  saturdayPmStatus: string;
  saturdayLocationId: number;
  saturdayPmLocationId: number;
  sundayAmStatus: string;
  sundayPmStatus: string;
  sundayLocationId: number;
  sundayPmLocationId: number;

  userId: string;

  mondayAmDeskLocation?: number;
  mondayPmDeskLocation?: number;
  tuesdayAmDeskLocation?: number;
  tuesdayPmDeskLocation?: number;
  wednesdayAmDeskLocation?: number;
  wednesdayPmDeskLocation?: number;
  thursdayAmDeskLocation?: number;
  thursdayPmDeskLocation?: number;
  fridayAmDeskLocation?: number;
  fridayPmDeskLocation?: number;
  saturdayAmDeskLocation?: number;
  saturdayPmDeskLocation?: number;
  sundayAmDeskLocation?: number;
  sundayPmDeskLocation?: number;

  mondayAmGeneralParking?: boolean;
  mondayPmGeneralParking?: boolean;
  tuesdayAmGeneralParking?: boolean;
  tuesdayPmGeneralParking?: boolean;
  wednesdayAmGeneralParking?: boolean;
  wednesdayPmGeneralParking?: boolean;
  thursdayAmGeneralParking?: boolean;
  thursdayPmGeneralParking?: boolean;
  fridayAmGeneralParking?: boolean;
  fridayPmGeneralParking?: boolean;
  saturdayAmGeneralParking?: boolean;
  saturdayPmGeneralParking?: boolean;
  sundayAmGeneralParking?: boolean;
  sundayPmGeneralParking?: boolean;
}

export interface MovementEntityRequest {
  date: string;
  id?: string;
  mondayAmStatus: string;
  mondayPmStatus: string;
  mondayLocationId: number;
  mondayPmLocationId: number;
  tuesdayAmStatus: string;
  tuesdayPmStatus: string;
  tuesdayLocationId: number;
  tuesdayPmLocationId: number;
  wednesdayAmStatus: string;
  wednesdayPmStatus: string;
  wednesdayLocationId: number;
  wednesdayPmLocationId: number;
  thursdayAmStatus: string;
  thursdayPmStatus: string;
  thursdayLocationId: number;
  thursdayPmLocationId: number;
  fridayAmStatus: string;
  fridayPmStatus: string;
  fridayLocationId: number;
  fridayPmLocationId: number;
  saturdayAmStatus: string;
  saturdayPmStatus: string;
  saturdayLocationId: number;
  saturdayPmLocationId: number;
  sundayAmStatus: string;
  sundayPmStatus: string;
  sundayLocationId: number;
  sundayPmLocationId: number;

  userId: string;

  mondayAmDeskLocation?: number;
  mondayPmDeskLocation?: number;
  tuesdayAmDeskLocation?: number;
  tuesdayPmDeskLocation?: number;
  wednesdayAmDeskLocation?: number;
  wednesdayPmDeskLocation?: number;
  thursdayAmDeskLocation?: number;
  thursdayPmDeskLocation?: number;
  fridayAmDeskLocation?: number;
  fridayPmDeskLocation?: number;
  saturdayAmDeskLocation?: number;
  saturdayPmDeskLocation?: number;
  sundayAmDeskLocation?: number;
  sundayPmDeskLocation?: number;

  mondayAmGeneralParking?: boolean;
  mondayPmGeneralParking?: boolean;
  tuesdayAmGeneralParking?: boolean;
  tuesdayPmGeneralParking?: boolean;
  wednesdayAmGeneralParking?: boolean;
  wednesdayPmGeneralParking?: boolean;
  thursdayAmGeneralParking?: boolean;
  thursdayPmGeneralParking?: boolean;
  fridayAmGeneralParking?: boolean;
  fridayPmGeneralParking?: boolean;
  saturdayAmGeneralParking?: boolean;
  saturdayPmGeneralParking?: boolean;
  sundayAmGeneralParking?: boolean;
  sundayPmGeneralParking?: boolean;
}

export interface MemberMovements {
  firstName: string;
  id: string;
  teamId: string;
  lastName: string;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  carParking?: UsersCarParkBooking[];
  movements: MovementEntity;
  departmentId?: number;
}

export interface UsersCarParkBooking {
  id: number;
  am: boolean;
  pm: boolean;
  officeId: number;
  period: Period;
  date: string;
}

export interface TeamMovements {
  teamEntity: TeamEntity;
  movements: Array<Movement>;
}

export interface UsersMovements {
  user: any;
  teamId: string;
  movements: Movement;
}

export interface SimpleUserWithLocation {
  userId: string;
  firstName: string;
  lastName: string;
  officeId: number;
  pmOfficeId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
}


export interface OfficeWithUsers {
  office?: OfficeEntity;
  area?: OfficeEntity;
  users: SimpleUserWithLocation[];
}

/**
 * Deprecated
 */
export interface MovementDay {
  movementId: string;
  locationId: number;
  userId: string;
  localDate: string;
  amStatus: string;
  pmStatus: string;
}

export interface WhereaboutsDay {
  whereaboutsId: string;
  userId: string;
  date: string;
  localDate: string;
  amLocationId: number;
  pmLocationId: number;
  amStatus: string;
  pmStatus: string;
  amGeneralParking: boolean;
  pmGeneralParking: boolean;
}

export interface WhereaboutsDayV2 {
  id: number;
  date: string;
  userId: string;
  firstName: string;
  lastName: string;
  amDeskId: number;
  amDeskLabel?: string;
  amOfficeId: number;
  amStatus: string;
  amStatusId: number;
  pmDeskId: number;
  pmDeskLabel?: string;
  pmOfficeId: number;
  pmStatus: string;
  pmStatusId: number;
  fireWarden: boolean;
  firstAider: boolean;
  mentalHealthAider: boolean;
  keyHolder: boolean;
  statusAllDay: boolean;
  teamId?: string;
  teamName?: string;

  amOption?: WhereaboutsOption;
  pmOption?: WhereaboutsOption;
}


export function toMovementsRequest(movement: Movement) {
  return {
    id: movement.id,
    userId: movement.userId,
    mondayAmStatus: movement.mondayAmStatus,
    mondayPmStatus: movement.mondayPmStatus,
    mondayLocationId: movement.mondayLocationId ?? 0,
    mondayPmLocationId: movement.mondayPmLocationId ?? 0,
    tuesdayAmStatus: movement.tuesdayAmStatus,
    tuesdayPmStatus: movement.tuesdayPmStatus,
    tuesdayLocationId: movement.tuesdayLocationId ?? 0,
    tuesdayPmLocationId: movement.tuesdayPmLocationId ?? 0,
    wednesdayAmStatus: movement.wednesdayAmStatus,
    wednesdayPmStatus: movement.wednesdayPmStatus,
    wednesdayLocationId: movement.wednesdayLocationId ?? 0,
    wednesdayPmLocationId: movement.wednesdayPmLocationId ?? 0,
    thursdayAmStatus: movement.thursdayAmStatus,
    thursdayPmStatus: movement.thursdayPmStatus,
    thursdayLocationId: movement.thursdayLocationId ?? 0,
    thursdayPmLocationId: movement.thursdayPmLocationId ?? 0,
    fridayAmStatus: movement.fridayAmStatus,
    fridayPmStatus: movement.fridayPmStatus,
    fridayLocationId: movement.fridayLocationId ?? 0,
    fridayPmLocationId: movement.fridayPmLocationId ?? 0,
    saturdayAmStatus: movement.saturdayAmStatus,
    saturdayPmStatus: movement.saturdayPmStatus,
    saturdayLocationId: movement.saturdayLocationId ?? 0,
    saturdayPmLocationId: movement.saturdayPmLocationId ?? 0,
    sundayAmStatus: movement.sundayAmStatus,
    sundayPmStatus: movement.sundayPmStatus,
    sundayLocationId: movement.sundayLocationId ?? 0,
    sundayPmLocationId: movement.sundayPmLocationId ?? 0,
    date: moment(movement.date).format('YYYY-MM-DD'),

    mondayAmDeskLocation: movement?.mondayAmDeskLocation ?? 0,
    mondayPmDeskLocation: movement?.mondayPmDeskLocation ?? 0,
    tuesdayAmDeskLocation: movement?.tuesdayAmDeskLocation ?? 0,
    tuesdayPmDeskLocation: movement?.tuesdayPmDeskLocation ?? 0,
    wednesdayAmDeskLocation: movement?.wednesdayAmDeskLocation ?? 0,
    wednesdayPmDeskLocation: movement?.wednesdayPmDeskLocation ?? 0,
    thursdayAmDeskLocation: movement?.thursdayAmDeskLocation ?? 0,
    thursdayPmDeskLocation: movement?.thursdayPmDeskLocation ?? 0,
    fridayAmDeskLocation: movement?.fridayAmDeskLocation ?? 0,
    fridayPmDeskLocation: movement?.fridayPmDeskLocation ?? 0,
    saturdayAmDeskLocation: movement?.saturdayAmDeskLocation ?? 0,
    saturdayPmDeskLocation: movement?.saturdayPmDeskLocation ?? 0,
    sundayAmDeskLocation: movement?.sundayAmDeskLocation ?? 0,
    sundayPmDeskLocation: movement?.sundayPmDeskLocation ?? 0,

    mondayAmGeneralParking: movement?.mondayAmGeneralParking ?? false,
    mondayPmGeneralParking: movement?.mondayPmGeneralParking ?? false,
    tuesdayAmGeneralParking: movement?.tuesdayAmGeneralParking ?? false,
    tuesdayPmGeneralParking: movement?.tuesdayPmGeneralParking ?? false,
    wednesdayAmGeneralParking: movement?.wednesdayAmGeneralParking ?? false,
    wednesdayPmGeneralParking: movement?.wednesdayPmGeneralParking ?? false,
    thursdayAmGeneralParking: movement?.thursdayAmGeneralParking ?? false,
    thursdayPmGeneralParking: movement?.thursdayPmGeneralParking ?? false,
    fridayAmGeneralParking: movement?.fridayAmGeneralParking ?? false,
    fridayPmGeneralParking: movement?.fridayPmGeneralParking ?? false,
    saturdayAmGeneralParking: movement?.saturdayAmGeneralParking ?? false,
    saturdayPmGeneralParking: movement?.saturdayPmGeneralParking ?? false,
    sundayAmGeneralParking: movement?.sundayAmGeneralParking ?? false,
    sundayPmGeneralParking: movement?.sundayPmGeneralParking ?? false,

    mondayAmParkingSpaceId: movement?.mondayAmParkingSpaceId ?? 0,
    mondayPmParkingSpaceId: movement?.mondayPmParkingSpaceId ?? 0,
    tuesdayAmParkingSpaceId: movement?.tuesdayAmParkingSpaceId ?? 0,
    tuesdayPmParkingSpaceId: movement?.tuesdayPmParkingSpaceId ?? 0,
    wednesdayAmParkingSpaceId: movement?.wednesdayAmParkingSpaceId ?? 0,
    wednesdayPmParkingSpaceId: movement?.wednesdayPmParkingSpaceId ?? 0,
    thursdayAmParkingSpaceId: movement?.thursdayAmParkingSpaceId ?? 0,
    thursdayPmParkingSpaceId: movement?.thursdayPmParkingSpaceId ?? 0,
    fridayAmParkingSpaceId: movement?.fridayAmParkingSpaceId ?? 0,
    fridayPmParkingSpaceId: movement?.fridayPmParkingSpaceId ?? 0,
    saturdayAmParkingSpaceId: movement?.saturdayAmParkingSpaceId ?? 0,
    saturdayPmParkingSpaceId: movement?.saturdayPmParkingSpaceId ?? 0,
    sundayAmParkingSpaceId: movement?.sundayAmParkingSpaceId ?? 0,
    sundayPmParkingSpaceId: movement?.sundayPmParkingSpaceId ?? 0,
  }
}

export function toWhereaboutsDays(movements: MovementEntity | MovementEntityRequest): WhereaboutsDay[] {
  const result: WhereaboutsDay[] = [];

  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).format(DATE_FORMAT),
    amLocationId: movements.mondayLocationId, pmLocationId: movements.mondayPmLocationId,
    amStatus: movements.mondayAmStatus, pmStatus: movements.mondayPmStatus,
    amGeneralParking: movements?.mondayAmGeneralParking ?? false, pmGeneralParking: movements?.mondayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(1, 'days').format(DATE_FORMAT),
    amLocationId: movements.tuesdayLocationId, pmLocationId: movements.tuesdayPmLocationId,
    amStatus: movements.tuesdayAmStatus, pmStatus: movements.tuesdayPmStatus,
    amGeneralParking: movements?.tuesdayAmGeneralParking ?? false, pmGeneralParking: movements?.tuesdayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(2, 'days').format(DATE_FORMAT),
    amLocationId: movements.wednesdayLocationId, pmLocationId: movements.wednesdayPmLocationId,
    amStatus: movements.wednesdayAmStatus, pmStatus: movements.wednesdayPmStatus,
    amGeneralParking: movements?.wednesdayAmGeneralParking ?? false, pmGeneralParking: movements?.wednesdayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(3, 'days').format(DATE_FORMAT),
    amLocationId: movements.thursdayLocationId, pmLocationId: movements.thursdayPmLocationId,
    amStatus: movements.thursdayAmStatus, pmStatus: movements.thursdayPmStatus,
    amGeneralParking: movements?.thursdayAmGeneralParking ?? false, pmGeneralParking: movements?.thursdayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(4, 'days').format(DATE_FORMAT),
    amLocationId: movements.fridayLocationId, pmLocationId: movements.fridayPmLocationId,
    amStatus: movements.fridayAmStatus, pmStatus: movements.fridayPmStatus,
    amGeneralParking: movements?.fridayAmGeneralParking ?? false, pmGeneralParking: movements?.fridayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(5, 'days').format(DATE_FORMAT),
    amLocationId: movements.saturdayLocationId, pmLocationId: movements.saturdayPmLocationId,
    amStatus: movements.saturdayAmStatus, pmStatus: movements.saturdayPmStatus,
    amGeneralParking: movements?.saturdayAmGeneralParking ?? false, pmGeneralParking: movements?.saturdayPmGeneralParking ?? false,
  });
  result.push({
    whereaboutsId: movements?.id ?? '', userId: movements.userId, date: movements.date, localDate: moment(movements.date).add(6, 'days').format(DATE_FORMAT),
    amLocationId: movements.sundayLocationId, pmLocationId: movements.sundayPmLocationId,
    amStatus: movements.sundayAmStatus, pmStatus: movements.sundayPmStatus,
    amGeneralParking: movements?.sundayAmGeneralParking ?? false, pmGeneralParking: movements?.sundayPmGeneralParking ?? false,
  });

  return result;
}
