import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { TeamEntity } from "../../models/team.models";
import { TeamWithMovements } from "../../models/movements.models";
import { requestTeamWeeklyMovements } from "../../services/Movements";

export enum ActiveCompanyView {
  Whereabouts = 'teams', OfficeView = 'office', FloorPlan = 'floor', Tags = 'tags'
}

export interface CompanyMovementsState {
  activeView: ActiveCompanyView;
  activeOfficeId?: number;
  teams: TeamWithMovements[];
  focusedTeamId?: string;
  loading: boolean;
  checkedTeamIds: string[];
  checkedWorkgroupIds: number[];
}

export const initialCompanyMovementsState: CompanyMovementsState = {
  activeView: ActiveCompanyView.Whereabouts,
  teams: [],
  loading: false,
  checkedTeamIds: [],
  checkedWorkgroupIds: [],
}

export const setAllActiveMovementsToLoading: any = createAsyncThunk(
  'companyMovements/setAllActiveMovementsToLoading',
  async (state: any, thunkAPI) => {
    const teams = ((thunkAPI.getState() as AppState).companyMovements.teams);
    await thunkAPI.dispatch(setTeams(teams.map((team) =>
      ({...team, movementsLoaded: false, movements: []})
    )));
  }
)

// TODO Refine this to invalidate a specific team and refresh all invalidated
export const refreshAllMovements: any = createAsyncThunk(
  'companyMovements/refreshAllMovements',
  async (state: any, thunkAPI) => {
    const teams = ((thunkAPI.getState() as AppState).companyMovements.teams);

    await thunkAPI.dispatch(setTeams(teams.map((team) => ({...team, movementsLoaded: false}))));

    await teams
      .filter(team => team.visible)
      .map(async (team) => {
        await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
        await thunkAPI.dispatch(loadTeamMovementById(team.team.id))
      })
  }
)

export const expandTeamMovements: any = createAsyncThunk(
  'companyMovements/expandTeamMovements',
  async (state: {team: TeamWithMovements, visibility: boolean}, thunkAPI) => {
    const teams = (thunkAPI.getState() as AppState).companyMovements.teams;
    const idx = teams.indexOf(state.team);
    if (idx < 0) throw new Error('Could not find team');

    const updatedTeam = { ...state.team, visible: state.visibility }

    await thunkAPI.dispatch(setTeamVisibility({team: state.team, visibility: state.visibility}))

    if (!teams[idx].movementsLoaded) {
      await thunkAPI.dispatch(loadTeamMovements(updatedTeam));
    }
  }
)

export const setTeamStructureForMovements: any = createAsyncThunk(
  'companyMovements/setTeamStructureForMovements',
  async (teams: TeamEntity[], thunkAPI) => {
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;

    // Put current users team at the top
    if (currentUser) {
      teams = teams.sort((a, b) => a.id === currentUser?.teamEntity?.id ? -1 : 1);
    }


    const teamsWithMovements = teams.map(t => {
      const isCurrentUsersTeam = currentUser?.teamEntity?.id === t.id;
      return new TeamWithMovements(t, isCurrentUsersTeam);
    });
    await thunkAPI.dispatch(setTeams(teamsWithMovements))
  }
)

export const loadTeamMovementById: any = createAsyncThunk(
  'companyMovements/loadTeamMovementsById',
  async (teamId: string, thunkAPI) => {
    if (!teamId) {
      return
    }
    const teams = (thunkAPI.getState() as AppState).companyMovements.teams;
    const team = teams.find(t => t.team.id === teamId);
    if (team) {
      thunkAPI.dispatch(loadTeamMovements(team));
    } else {
      console.warn('Cound not find team')
    }
  }
)

export const setFocusedTeamId: any = createAsyncThunk(
  'companyMovements/setFocusedTeamId',
  async (teamId: string, thunkAPI) => {
    if (!teamId) {
      return
    }
    const teams = (thunkAPI.getState() as AppState).companyMovements.teams;
    const team = teams.find(t => t.team.id === teamId);
    if (team) {
      team.visible = true;
      thunkAPI.dispatch(loadTeamMovements(team));
    } else {
      console.warn('Cound not find team')
    }

    return teamId;
  }
)

export const loadTeamMovements: any = createAsyncThunk(
  'companyMovements/loadTeamMovements',
  async (team: TeamWithMovements, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const movementsResponse = await requestTeamWeeklyMovements(team.team.id, state.dashboard.calendarDate);

    const currentUser = state.auth.currentUser;
    const isCurrentUsersTeam = currentUser?.teamEntity && currentUser?.teamEntity?.id === team.team.id;

    // Move current user to the top of the list
    if (isCurrentUsersTeam) {
      movementsResponse.teamMovements = movementsResponse.teamMovements
        .sort((a, b) => a.id === currentUser?.id ? -1 : 1)
    }

    return {
      teamToUpdate: team,
      movementsResponse: movementsResponse,
      isCurrentUsersTeam: isCurrentUsersTeam,
    }
  }
)

const companyMovementsSlice = createSlice({
  name: 'companyMovements',
  initialState: initialCompanyMovementsState,
  reducers: {
    setTeams: (state, action) => ({...state, teams: action.payload}),
    setActiveCompanyView: (state, action) => ({...state, activeView: action.payload}),
    setActiveOfficeId: (state, action) => ({...state, activeOfficeId: action.payload}),
    setCheckedTeamIds: (state, action) => ({...state, checkedTeamIds: action.payload}),
    setCheckedWorkgroupIds: (state, action) => ({...state, checkedWorkgroupIds: action.payload}),
    setTeamVisibility: (state, action) => {
      const { team, visibility } = action.payload;
      const updatedTeam = { ...team, visible: visibility }
      const updatedTeams = state.teams.map((obj: TeamWithMovements) => (obj.team.id === team.team.id) ? updatedTeam : obj);
      return {
        ...state,
        teams: updatedTeams,
      }
    },
  },
  extraReducers: {
    [setFocusedTeamId.fulfilled]: (state, action) => {
      return {...state, focusedTeamId: action.payload};
    },
    [loadTeamMovements.fulfilled]: (state, action) => {
      const {teamToUpdate, movementsResponse, isCurrentUsersTeam} = action.payload

      const teams = state.teams.map((obj: TeamWithMovements) => {
        return (obj.team.id === teamToUpdate.team.id) ? ({
          ...teamToUpdate,
          movementsLoaded: true,
          userDesks: movementsResponse.userDesks,
          movements: movementsResponse.teamMovements ?? [],
          visible: isCurrentUsersTeam ? true : teamToUpdate.visible,
        }) : obj
      });

      return { ...state, teams: teams }
    },
  }
});

export const {
  setActiveCompanyView,
  setActiveOfficeId,
  setTeams,
  setTeamVisibility,
  setCheckedTeamIds,
  setCheckedWorkgroupIds,
} = companyMovementsSlice.actions;
export default companyMovementsSlice.reducer;

// Selectors
export const selectActiveView = (state: AppState) => state.companyMovements.activeView;
export const selectActiveOfficeId = (state: AppState) => state.companyMovements.activeOfficeId;
export const selectCheckedTeamIds = (state: AppState) => state.companyMovements.checkedTeamIds;
export const selectCheckedWorkgroupIds = (state: AppState) => state.companyMovements.checkedWorkgroupIds;
