import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../UI/organisms/layout/Layout';
import { HeadlineLarge } from '../../UI/atoms/fonts/Headline';
import Calendar from '../../UI/molecules/calendar/Calendar';
import './styles/team-movements.scss';
import { AddTeamMemberButton } from "../../UI/atoms/buttons/AddTeamMemberButton";
import { redirectTo, selectCurrentUser } from "../../../store/ducks/auth.duck";
import { Column, Row } from '../../UI/atoms/StructuralLayout';
import { selectCalendarDate, setCalendarDate } from "../../../store/ducks/dashboard.duck";
import { VerticalSpacer } from "../../UI/atoms/VerticalSpacer";
import { useParams } from "react-router";
import { requestTeamWeeklyMovements } from "../../../services/Movements";
import { TeamWithMovements } from "../../../models/movements.models";
import { TeamMovementResponse } from "../../../models/api.models";
import { deepLinkTo, isInTeams } from "../../../utils/TeamsUtils";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { teamsTabs, uiUrl } from "../../../services/EnvironmentVariables";
import { TeamWallChart } from "../../UI/organisms/movements-wallchart/TeamWallChart";
import { CurrentUsersWeeklyWhereabouts } from "../../UI/organisms/CurrentUsersWeeklyWhereabouts";
import WallChartHeader from "../../UI/organisms/movements-wallchart/components/WallChartHeader/WallChartHeader";
import { useTranslation } from "react-i18next";

export default function TeamSearchResultsPage() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const teamId = useMemo(() => params.teamId, [params]);
  const calendarDate = useSelector(selectCalendarDate);
  const current = useSelector(selectCurrentUser);
  const [teamMovements, setTeamMovements] = useState<TeamWithMovements | undefined>(undefined);
  const {t} = useTranslation();

  useEffect(() => {
    if (!teamId || !calendarDate) {
      return;
    }
    requestTeamWeeklyMovements(teamId, calendarDate)
      .then((resp: TeamMovementResponse) => new TeamWithMovements(resp.teamEntity, true, true, resp.teamMovements, resp.userDesks, resp.restricted))
      .then(setTeamMovements)
  }, [teamId, calendarDate]);

  const onGoBackClick = () => {
    if (isInTeams()) {
      deepLinkTo(teamsTabs.team, `${uiUrl}/manage`);
    } else {
      dispatch(redirectTo('/whereabouts/teams'));
    }
  }

  return <>
    <Layout>
      <Row className="new-teamMovements">
        <Column className="teamMovementsContent" style={{maxWidth: current?.companyEntity.sevenDayWhereaboutsWeekEnabled ? 1100 : 1000}}>
          {isInTeams() && <GoBackButton onClick={onGoBackClick} text="button.back-to-team-view" url="/whereabouts/team" />}
          <HeadlineLarge data-test="headline" style={{marginBottom: 16}}>{t('Search results')}</HeadlineLarge>
          <WallChartHeader/>
          <CurrentUsersWeeklyWhereabouts style={{marginBottom: 24}} />
          {!!teamMovements ? <TeamWallChart movementsViewObject={teamMovements}
                                            disableAccordian={true}
                                            showHeader={true}
                                            showFooter={true}
                                            enabledSetMovements={true}/> : <></>}
        </Column>
        <VerticalSpacer />
        <Column className="teamMovementsSidebar">
          <Calendar initialDate={calendarDate} onDateSelect={(date) => dispatch(setCalendarDate(date))}/>
          <AddTeamMember/>
        </Column>
      </Row>
    </Layout>
  </>
}

function AddTeamMember(props: { className?: string }) {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Column className={`addTeamMember ${props.className ?? ''}`.trim()}>
      <img src="/assets/images/man-fishing.svg" alt="Man fishing" aria-hidden="true"/>
      <AddTeamMemberButton team={currentUser?.teamEntity}/>
    </Column>
  )
}


